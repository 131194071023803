import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
// @mui material components
// import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
// import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
// import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import Footer from "examples/Footer";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import MDInput from "components/MDInput";
import MDSnackbar from "components/MDSnackbar";
import Autocomplete from "@mui/material/Autocomplete";

import MDButton from "components/MDButton";

import DataTable from "examples/Tables/DataTable";

import Parse from "parse";
import { Input, TextField } from "@mui/material";

import { useParseQuery } from "@parse/react";

const asyncForEach = async (array, callback) => {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
};
const delay = (ms) => new Promise((res) => setTimeout(res, ms));

export default function CreateGR() {
  let navigate = useNavigate();
  useEffect(async () => {
    if (!Parse.User.current()) {
      navigate("/authentication/sign-in");
    }
    // subordinates = await getSubordinates();
    return () => {
      // console.log("subscription closed");
    };
  }, []);
  useEffect(async () => {
    if (!Parse.User.current()) {
      navigate("/authentication/sign-in");
    }
  }, [Parse.User.current()]);

  const [materialName, setMaterialName] = useState("");
  const [quantity, setQuantity] = useState("");
  const [unit, setUnit] = useState("");
  const [items, setItems] = useState([]);
  const [availableItems, setAvailableItems] = useState([]);
  const [currentItem, setCurrentItem] = useState(null);

  const [inputWidth, setInputWidth] = useState(0);
  const [inputHeight, setInputHeight] = useState(0);

  const [successSB, setSuccessSB] = useState(false);
  const [infoSB, setInfoSB] = useState(false);
  const [warningSB, setWarningSB] = useState(false);
  const [errorSB, setErrorSB] = useState(false);
  const [error, setError] = useState("");

  const openSuccessSB = () => setSuccessSB(true);
  const closeSuccessSB = () => setSuccessSB(false);
  const openInfoSB = () => setInfoSB(true);
  const closeInfoSB = () => setInfoSB(false);
  const openWarningSB = () => setWarningSB(true);
  const closeWarningSB = () => setWarningSB(false);
  const openErrorSB = () => setErrorSB(true);
  const closeErrorSB = () => setErrorSB(false);

  const renderSuccessSB = (
    <MDSnackbar
      color="success"
      icon="check"
      title="IMS Demo"
      content="GR Request created successfully"
      dateTime=""
      open={successSB}
      onClose={closeSuccessSB}
      close={closeSuccessSB}
      bgWhite
    />
  );

  const renderInfoSB = (
    <MDSnackbar
      icon="notifications"
      title="Material Dashboard"
      content="Hello, world! This is a notification message"
      dateTime=""
      open={infoSB}
      onClose={closeInfoSB}
      close={closeInfoSB}
    />
  );

  const renderWarningSB = (
    <MDSnackbar
      color="warning"
      icon="warning_amber_outlined"
      title="IMS Demo"
      content="Please fill all necessary fields"
      dateTime=""
      open={warningSB}
      onClose={closeWarningSB}
      close={closeWarningSB}
      bgWhite
    />
  );

  const renderErrorSB = (
    <MDSnackbar
      color="error"
      icon="warning"
      title="IMS Demo"
      content={error || "Something went wrong. Try again or contact support."}
      dateTime=""
      open={errorSB}
      onClose={closeErrorSB}
      close={closeErrorSB}
      bgWhite
    />
  );

  const ref = useRef(null);
  // const refSelect = useRef(null);

  useEffect(async () => {
    let Inventory = new Parse.Query("Inventory");
    // Inventory.equalTo("name", materialName);
    Inventory.ascending("name");
    let existingItems = await Inventory.find();
    // console.log(existingItems);
    setAvailableItems(existingItems);
  }, []);

  useEffect(() => {
    // console.log("width", ref.current ? ref.current.offsetWidth : 0);
    setInputWidth(ref.current ? ref.current.offsetWidth : 0);
    // console.log("height", ref.current ? ref.current.offsetHeight : 0);
    // setInputHeight(ref.current ? ref.current.offsetHeight : 0);
  }, [ref.current]);
  // useEffect(() => {
  //   // console.log("width", ref.current ? ref.current.offsetWidth : 0);
  //   // setInputWidth(ref.current ? ref.current.offsetWidth : 0);
  //   console.log("height", refSelect.current ? refSelect.current.offsetHeight : 0);
  //   setInputHeight(refSelect.current ? refSelect.current.offsetHeight : 0);
  // }, [refSelect.current]);

  const addItem = async (e) => {
    e.preventDefault();
    // console.log(currentItem);
    // console.log(quantity);
    // console.log(unit);
    if (!currentItem || !quantity) {
      openWarningSB();
      return;
    }
    // console.log(currentItem);
    // console.log(currentItem.attributes.unit);
    const newItems = [
      ...items,
      {
        quantity,
        id: currentItem.id,
        currentItem,
      },
    ];
    console.log(newItems);
    await setItems(newItems);
    await setCurrentItem(null);
    // await setMaterialName("");
    await setQuantity(0);
    await setUnit("");
  };
  const deleteItem = (index, e) => {
    e.preventDefault();
    setItems([...items.slice(0, index), ...items.slice(index + 1)]);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (currentItem) {
      // await addItem(e);
      // await delay(1500);
      setError("Please add items before submitting");
      openErrorSB();
      return;
    }
    if (!items.length) {
      setError("No items added");
      openErrorSB();
      return;
    }
    // asyncForEach(items, async (item) => {
    let newMaterial = new Parse.Object("Transactions");
    // console.log(item);
    newMaterial.set("user", Parse.User.current());
    newMaterial.set("details", items);
    newMaterial.set("status", "confirmed");
    newMaterial.set("action", "add");
    await newMaterial
      .save()
      .then(() => {
        openSuccessSB();
      })
      .catch(() => {
        openErrorSB();
      });
    // console.log("submitted");
    setItems([]);
    setCurrentItem(null);
    setQuantity(0);
    // });
  };
  const createRows = () => {
    const rows = items.map((item, index) => ({
      iNum: index + 1,
      itemCode: item.currentItem.id,
      item: item.currentItem.attributes.name,
      quantity: item.quantity,
      unit: item.currentItem.attributes.unit,
      actions: (
        <MDTypography
          component="a"
          href="#"
          variant="caption"
          color="error"
          fontWeight="medium"
          onClick={(e) => deleteItem(index, e)}
        >
          Delete
        </MDTypography>
      ),
    }));
    // console.log(rows);
    return rows;
  };
  const createForm = () => {
    const form = {
      iNum: items.length + 1,
      // itemCode: `item${items.length + 1}`,
      item: (
        <Autocomplete
          defaultValue={null}
          // ref={refSelect}
          options={availableItems}
          getOptionLabel={(item) => item.attributes.name}
          value={currentItem}
          onChange={(event, newValue) => setCurrentItem(newValue)}
          sx={{ width: inputWidth }}
          fullWidth={true}
          renderInput={(params) => (
            // <MDInput
            //   type="text"
            //   label="Manager"
            //   // fullWidth={true}
            //   // variant="outlined"
            //   // mt={3}
            //   // value={material}

            //   style={{ paddingY: 0 }}
            //   // onChange={(e) => setManager(e.target.value)}
            // />
            <TextField
              type="text"
              label="Item Name"
              {...params}
              inputProps={{
                ...params.inputProps,
                style: {
                  // height: inputHeight,
                  // height: "35px",
                  padding: 3,
                },
              }}
              // value={materialName}
              // onChange={(e) => setMaterialName(e.target.value)}
            />
          )}
        />
      ),
      quantity: (
        <MDInput
          ref={ref}
          type="number"
          variant="outlined"
          label="Quantity"
          min="0"
          value={quantity}
          // sx={{ paddingY: "9px" }}
          // sx={{ height: inputHeight + 3 }}

          onChange={(e) => {
            let val = parseInt(e.target.value, 10);
            if (Number.isNaN(val)) {
              setQuantity("");
            } else {
              // is A Number
              val = val >= 0 ? val : 0;
              setQuantity(val);
            }
          }}
        />
      ),
      unit: (
        // <MDInput
        //   type="text"
        //   label="Unit"
        //   // value={material}
        //   onChange={(e) => setUnit(e.target.value)}
        // />
        <MDTypography variant="caption" color="text" fontWeight="medium">
          {currentItem && currentItem.attributes.unit}
        </MDTypography>
        // <></>
      ),
    };
    return form;
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Create GR Request
                </MDTypography>
              </MDBox>
              <MDBox component="form" onSubmit={addItem}>
                <DataTable
                  table={{
                    columns: [
                      { Header: "Item Num", accessor: "iNum", width: "1%", align: "left" },
                      { Header: "Item Code", accessor: "itemCode", align: "left" },
                      { Header: "Item", accessor: "item", align: "left" },
                      { Header: "quantity", accessor: "quantity", align: "center" },
                      { Header: "unit", accessor: "unit", align: "center" },
                      { Header: "actions", accessor: "actions", align: "center" },
                    ],
                    rows: [...createRows(), createForm()],
                  }}
                  isSorted={false}
                  entriesPerPage={{ defaultValue: 100, entries: [50, 100, 150, 200, 250] }}
                  showTotalEntries={false}
                  // editRowsModel
                  // noEndBorder
                />
              </MDBox>
              <Grid
                container
                // xs={12}
                direction="row"
                justifyContent="space-evenly"
                alignItems="center"
                px="auto"
                mb={2}
                mt={3}
              >
                <Grid item>
                  <MDButton color="info" onClick={addItem}>
                    Add item
                  </MDButton>
                </Grid>
                <Grid item>
                  <MDButton color="success" onClick={handleSubmit}>
                    Submit
                  </MDButton>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      {renderSuccessSB}
      {renderInfoSB}
      {renderWarningSB}
      {renderErrorSB}

      {/* <Footer /> */}
    </DashboardLayout>
  );
}
