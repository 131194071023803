import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDBadge from "components/MDBadge";
import MDButton from "components/MDButton";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

import Parse from "parse";
import { useParseQuery } from "@parse/react";

function Inventory() {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Info
                </MDTypography>
              </MDBox>
              <Grid container justifyContent="center" alignItems="center">
                <Grid item xs={10} p={5}>
                  <MDTypography variant="h2" pt={2}>
                    1. Logging In
                  </MDTypography>
                  <MDTypography variant="p" pt={1} pb={2}>
                    Username and password are same. Add @ims.ravimakes.com to the username for
                    email. Example: To login as admin, use email as admin@ims.ravimakes.com and
                    password as admin.
                  </MDTypography>
                  <MDTypography variant="h2" pt={2}>
                    2. Logging Out
                  </MDTypography>
                  <MDTypography variant="p" pt={1} pb={2}>
                    A Log Out button is available in the{" "}
                    <MDTypography component="a" href="/profile">
                      Profile
                    </MDTypography>{" "}
                    page.
                  </MDTypography>
                  <MDTypography variant="h2" pt={2}>
                    3. Existing users and employee structure
                  </MDTypography>
                  <img src="/OrgChart.svg" alt="Org Chart for IMS Demo" width="100%" />
                  <MDTypography variant="h2" pt={-4}>
                    4. Permissions
                  </MDTypography>
                  <MDTypography variant="p" pt={1} pb={2}>
                    In production, permissions are set using roles and groups. The demo does not
                    have enough users at the moment and permissions are hardcoded. Different users
                    have different permissions. These permissions can be viewed in the Profile page
                    at any time. Below is a list of permissions set in the Demo
                    <li>
                      Project Manager: Material Issue Request, Material Request Approval, Create New
                      Material, Create New User
                    </li>
                    <li>Store Manager: Create New Material, Create GR</li>
                    <li>
                      Manager 1 and Manager 2: Material Issue Request, Material Request Approval
                    </li>
                    <li>Engineer 1, Engineer 2 and Engineer 3: Material Issue Request</li>
                  </MDTypography>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Grid>
      </MDBox>

      {/* <Footer /> */}
    </DashboardLayout>
  );
}

export default Inventory;
