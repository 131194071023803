/**
=========================================================
* Material Dashboard 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect, useLayoutEffect } from "react";

// react-router-dom components
import { Link, useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";
import Modal from "@mui/material/Modal";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import GitHubIcon from "@mui/icons-material/GitHub";
import GoogleIcon from "@mui/icons-material/Google";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

import { useMaterialUIController } from "context";

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import Parse from "parse";

// Parse.serverURL = process.env.serverURL || "http://192.168.1.25:1337/parse";
// Parse.initialize("ims", "ims123");

function Basic() {
  const [rememberMe, setRememberMe] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [successSB, setSuccessSB] = useState(false);
  const [infoSB, setInfoSB] = useState(false);
  const [warningSB, setWarningSB] = useState(false);
  const [errorSB, setErrorSB] = useState(false);
  const [error, setError] = useState("");

  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const [infoModal, setInfoModal] = useState(false);

  const openSuccessSB = () => setSuccessSB(true);
  const closeSuccessSB = () => setSuccessSB(false);
  const openInfoSB = () => setInfoSB(true);
  const closeInfoSB = () => setInfoSB(false);
  const openWarningSB = () => setWarningSB(true);
  const closeWarningSB = () => setWarningSB(false);
  const openErrorSB = () => setErrorSB(true);
  const closeErrorSB = () => setErrorSB(false);

  const renderSuccessSB = (
    <MDSnackbar
      color="success"
      icon="check"
      title="IMS Demo"
      content="Logged in successfully. Redirecting you to your dashboard."
      dateTime=""
      open={successSB}
      onClose={closeSuccessSB}
      close={closeSuccessSB}
      bgWhite
    />
  );

  const renderInfoSB = (
    <MDSnackbar
      icon="notifications"
      title="Material Dashboard"
      content="Hello, world! This is a notification message"
      dateTime=""
      open={infoSB}
      onClose={closeInfoSB}
      close={closeInfoSB}
    />
  );

  const renderWarningSB = (
    <MDSnackbar
      color="warning"
      icon="warning_amber_outlined"
      title="IMS Demo"
      content="Please fill all necessary fields"
      dateTime=""
      open={warningSB}
      onClose={closeWarningSB}
      close={closeWarningSB}
      bgWhite
    />
  );

  const renderErrorSB = (
    <MDSnackbar
      color="error"
      icon="warning"
      title="IMS Demo"
      content={error || "Something went wrong. Try again or contact support."}
      dateTime=""
      open={errorSB}
      onClose={closeErrorSB}
      close={closeErrorSB}
      bgWhite
    />
  );

  const handleSetRememberMe = () => setRememberMe(!rememberMe);
  const navigate = useNavigate();

  const handleLogin = (e) => {
    e.preventDefault();
    if (!email || !password) {
      openWarningSB();
      return;
    }
    Parse.User.logIn(email, password)
      .then(async () => {
        // this.props.history.goBack();
        let uid = Parse.User.current().id;
        // await localStorage.setItem(`Parse_LDS__User_${uid}`, [
        //   JSON.stringify(Parse.User.current()),
        // ]);
        openSuccessSB();
        setTimeout(() => {
          window.location = "/dashboard";
        }, 1500);
        // setTimeout(() => {
        //   navigate("/dashboard");
        // }, 1500);
      })
      .catch((err) => {
        console.log(err.message);
        setError(err.message);
        openErrorSB();
      });
  };

  useLayoutEffect(() => {
    if (Parse.User.current()) {
      // window.location = "/dashboard";
      navigate("/dashboard");
    }
    // return () => {};
  });

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Sign in
          </MDTypography>
          <Grid container spacing={3} justifyContent="center" sx={{ mt: 1, mb: 2 }}>
            <Grid item xs={2}>
              <MDTypography component={MuiLink} href="#" variant="body1" color="white">
                <FacebookIcon color="inherit" />
              </MDTypography>
            </Grid>
            <Grid item xs={2}>
              <MDTypography component={MuiLink} href="#" variant="body1" color="white">
                <GitHubIcon color="inherit" />
              </MDTypography>
            </Grid>
            <Grid item xs={2}>
              <MDTypography component={MuiLink} href="#" variant="body1" color="white">
                <GoogleIcon color="inherit" />
              </MDTypography>
            </Grid>
          </Grid>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput
                type="email"
                label="Email"
                fullWidth
                onChange={(e) => setEmail(e.target.value)}
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="password"
                label="Password"
                fullWidth
                onChange={(e) => setPassword(e.target.value)}
              />
            </MDBox>
            <MDBox display="flex" alignItems="center" ml={-1}>
              <Switch checked={rememberMe} onChange={handleSetRememberMe} />
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                onClick={handleSetRememberMe}
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;Remember me
              </MDTypography>
            </MDBox>
            <Grid
              container
              mt={4}
              mb={1}
              columnSpacing={1}
              justifyContent="center"
              alignItems="center"
              fullWidth
            >
              <Grid item xs={9} paddingLeft={0}>
                <MDButton variant="gradient" fullWidth color="info" onClick={handleLogin}>
                  sign in
                </MDButton>
              </Grid>
              <Grid item xs={3}>
                <MDButton
                  variant="gradient"
                  fullWidth
                  color="info"
                  onClick={() => setInfoModal(true)}
                  sx={{ marginRight: 0 }}
                >
                  ?
                </MDButton>
              </Grid>
            </Grid>
            {/* <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                Don&apos;t have an account?{" "}
                <MDTypography
                  component={Link}
                  to="/authentication/sign-up"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Sign up
                </MDTypography>
              </MDTypography>
            </MDBox> */}
          </MDBox>
        </MDBox>
      </Card>
      {renderSuccessSB}
      {renderInfoSB}
      {renderWarningSB}
      {renderErrorSB}
      <Modal
        open={infoModal}
        onClose={() => {
          setInfoModal(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        // sx={{
        //   position: "absolute",
        //   top: "10%",
        //   left: "10%",
        //   overflow: "scroll",
        //   height: "100vh",
        //   // display: "block",
        // }}
      >
        <MDBox
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "75%",
            // maxWidth: "xs",
          }}
          color={darkMode ? "dark" : "light"}
          bgColor={darkMode ? "dark" : "light"}
          variant="gradient"
          borderRadius="lg"
          shadow="lg"
          opacity={1}
          p={2}
        >
          <MDTypography id="modal-modal-title" variant="h6" component="h2">
            Info
          </MDTypography>
          <MDBox
            id="modal-modal-description"
            // pt={3}
            sx={{
              // position: "absolute",
              // top: "10%",
              // left: "10%",
              overflow: "scroll",
              height: "80vh",
              overflowX: "hidden !important",
              display: "block",
            }}
          >
            <Grid container justifyContent="center" alignItems="center">
              <Grid item xs={10} p={0}>
                <MDTypography variant="h2" pt={2}>
                  1. Logging In
                </MDTypography>
                <MDTypography variant="p" pt={1} pb={2}>
                  Username and password are same. Add @ims.ravimakes.com to the username for email.
                  Example: To login as admin, use email as admin@ims.ravimakes.com and password as
                  admin.
                </MDTypography>
                <MDTypography variant="h2" pt={2}>
                  2. Logging Out
                </MDTypography>
                <MDTypography variant="p" pt={1} pb={2}>
                  A Log Out button is available in the{" "}
                  <MDTypography component="a" href="/profile">
                    Profile
                  </MDTypography>{" "}
                  page.
                </MDTypography>
                <MDTypography variant="h2" pt={2}>
                  3. Existing users and employee structure
                </MDTypography>
                <img src="/OrgChart.svg" alt="Org Chart for IMS Demo" width="100%" />
                <MDTypography variant="h2" pt={-4}>
                  4. Permissions
                </MDTypography>
                <MDTypography variant="p" pt={1} pb={2}>
                  In production, permissions are set using roles and groups. The demo does not have
                  enough users at the moment and permissions are hardcoded. Different users have
                  different permissions. These permissions can be viewed in the Profile page at any
                  time. Below is a list of permissions set in the Demo
                  <li>
                    Project Manager: Material Issue Request, Material Request Approval, Create New
                    Material, Create New User
                  </li>
                  <li>Store Manager: Create New Material, Create GR</li>
                  <li>
                    Manager 1 and Manager 2: Material Issue Request, Material Request Approval
                  </li>
                  <li>Engineer 1, Engineer 2 and Engineer 3: Material Issue Request</li>
                </MDTypography>
              </Grid>
            </Grid>
          </MDBox>
        </MDBox>
      </Modal>
    </BasicLayout>
  );
}

export default Basic;
