import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
// @mui material components
// import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
// import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
// import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import Footer from "examples/Footer";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDSnackbar from "components/MDSnackbar";

import MDInput from "components/MDInput";
import Autocomplete from "@mui/material/Autocomplete";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";

import MDButton from "components/MDButton";

import DataTable from "examples/Tables/DataTable";

import Parse from "parse";

const asyncForEach = async (array, callback) => {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
};

export default function CreateNewMaterial() {
  let navigate = useNavigate();
  useEffect(async () => {
    if (!Parse.User.current()) {
      navigate("/authentication/sign-in");
    }
    // subordinates = await getSubordinates();
    return () => {
      // console.log("subscription closed");
    };
  }, []);
  useEffect(async () => {
    if (!Parse.User.current()) {
      navigate("/authentication/sign-in");
    }
  }, [Parse.User.current()]);

  const [materialName, setMaterialName] = useState("");
  const [unit, setUnit] = useState("");
  const [description, setDescription] = useState("");
  const [items, setItems] = useState([]);
  const [successSB, setSuccessSB] = useState(false);
  const [infoSB, setInfoSB] = useState(false);
  const [warningSB, setWarningSB] = useState(false);
  const [errorSB, setErrorSB] = useState(false);
  const [error, setError] = useState("");

  const openSuccessSB = () => setSuccessSB(true);
  const closeSuccessSB = () => setSuccessSB(false);
  const openInfoSB = () => setInfoSB(true);
  const closeInfoSB = () => setInfoSB(false);
  const openWarningSB = () => setWarningSB(true);
  const closeWarningSB = () => setWarningSB(false);
  const openErrorSB = () => setErrorSB(true);
  const closeErrorSB = () => setErrorSB(false);

  const renderSuccessSB = (
    <MDSnackbar
      color="success"
      icon="check"
      title="IMS Demo"
      content="Item(s) created successfully"
      dateTime=""
      open={successSB}
      onClose={closeSuccessSB}
      close={closeSuccessSB}
      bgWhite
    />
  );

  const renderInfoSB = (
    <MDSnackbar
      icon="notifications"
      title="Material Dashboard"
      content="Hello, world! This is a notification message"
      dateTime=""
      open={infoSB}
      onClose={closeInfoSB}
      close={closeInfoSB}
    />
  );

  const renderWarningSB = (
    <MDSnackbar
      color="warning"
      icon="warning_amber_outlined"
      title="IMS Demo"
      content="Please fill all necessary fields"
      dateTime=""
      open={warningSB}
      onClose={closeWarningSB}
      close={closeWarningSB}
      bgWhite
    />
  );

  const renderErrorSB = (
    <MDSnackbar
      color="error"
      icon="warning"
      title="IMS Demo"
      content={error || "Something went wrong. Try again or contact support."}
      dateTime=""
      open={errorSB}
      onClose={closeErrorSB}
      close={closeErrorSB}
      bgWhite
    />
  );

  const addItem = async (e) => {
    e.preventDefault();
    if (!materialName || !unit) {
      openWarningSB();
      return;
    }
    let Inventory = new Parse.Query("Inventory");
    Inventory.equalTo("name", materialName);
    let existingItems = await Inventory.find();
    if (existingItems.length > 0) {
      setError("Material already exists");
      openErrorSB();
      return;
    }
    const newItems = [...items, { materialName, description, unit }];
    setItems(newItems);
    setMaterialName("");
    setDescription("");
    setUnit("");
  };
  const deleteItem = (index, e) => {
    e.preventDefault();
    setItems([...items.slice(0, index), ...items.slice(index + 1)]);
  };
  const submitItems = async (e) => {
    e.preventDefault();
    // items.forEach((item) => {
    //   console.log(item);
    //   newMaterial.set("name", item.materialName);
    //   newMaterial.set("description", item.description);
    //   newMaterial.set("unit", item.unit);
    //   newMaterial.save();
    // });
    asyncForEach(items, async (item) => {
      let newMaterial = new Parse.Object("Inventory");
      console.log(item);
      newMaterial.set("name", item.materialName);
      newMaterial.set("description", item.description);
      newMaterial.set("unit", item.unit);
      newMaterial.set("quantity", 0);
      newMaterial.save();
      openSuccessSB();
    });
    setItems([]);
  };
  // useEffect(() => {
  //   console.log(materialName);
  // }, [materialName]);
  const createRows = () => {
    const rows = items.map((item, index) => ({
      iNum: index + 1,
      itemCode: `item${index + 1}`,
      item: item.materialName,
      itemImage: "",
      description: item.description,
      unit: item.unit,
      actions: (
        <MDTypography
          component="a"
          href="#"
          variant="caption"
          color="error"
          fontWeight="medium"
          onClick={(e) => deleteItem(index, e)}
        >
          Delete
        </MDTypography>
      ),
    }));
    // console.log(rows);
    return rows;
  };
  const createForm = () => {
    const form = {
      iNum: items.length + 1,
      itemCode: `item${items.length + 1}`,
      item: (
        <MDInput
          type="text"
          label="Material"
          // value={materialName}
          onChange={(e) => setMaterialName(e.target.value)}
        />
      ),
      description: (
        <MDInput
          type="text"
          label="Description"
          // value={material}
          onChange={(e) => setDescription(e.target.value)}
        />
      ),
      unit: (
        <MDInput
          type="text"
          label="Unit"
          // value={material}
          sx={{ width: 60 }}
          onChange={(e) => setUnit(e.target.value)}
        />
      ),
    };
    return form;
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Create New Material
                </MDTypography>
              </MDBox>
              <MDBox component="form" onSubmit={addItem}>
                <DataTable
                  table={{
                    columns: [
                      { Header: "Item Num", accessor: "iNum", width: "1%", align: "left" },
                      // { Header: "Item Code", accessor: "itemCode", width: "1%", align: "left" },
                      { Header: "Image", accessor: "itemImage", width: "1%", align: "center" },
                      { Header: "Item", accessor: "item", align: "center" },
                      {
                        Header: "Description",
                        accessor: "description",
                        width: "10%",
                        align: "center",
                      },
                      { Header: "unit", accessor: "unit", align: "center" },
                      { Header: "actions", accessor: "actions", align: "center" },
                    ],
                    rows: [...createRows(), createForm()],
                  }}
                  isSorted={false}
                  entriesPerPage={{ defaultValue: 100, entries: [50, 100, 150, 200, 250] }}
                  showTotalEntries={false}
                  // noEndBorders
                />
              </MDBox>
              <Grid
                container
                // xs={12}
                direction="row"
                justifyContent="space-evenly"
                alignItems="center"
                px="auto"
                mb={2}
                mt={3}
              >
                <Grid item>
                  <MDButton color="info" onClick={addItem}>
                    Add another item
                  </MDButton>
                </Grid>
                <Grid item>
                  <MDButton color="success" onClick={submitItems}>
                    Submit
                  </MDButton>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      {renderSuccessSB}
      {renderInfoSB}
      {renderWarningSB}
      {renderErrorSB}

      {/* <Footer /> */}
    </DashboardLayout>
  );
}
