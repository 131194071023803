/**
=========================================================
* Material Dashboard 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Soft UI Dashboard React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

/* eslint-disable import/no-mutable-exports */
// Material Dashboard 2 React layouts
import Dashboard from "layouts/dashboard";
import CreateGR from "layouts/createGR";
import CreateNewMaterial from "layouts/createNewMaterial";
import CreateNewUser from "layouts/createNewUser";
import MaterialIssueRequest from "layouts/materialIssueRequest";
import Temp from "layouts/temp";
import Inventory from "layouts/inventory";
// import Tables from "layouts/tables";
// import Billing from "layouts/billing";
// import RTL from "layouts/rtl";
// import Notifications from "layouts/notifications";
import Profile from "layouts/profile";
import Info from "layouts/info";
import SignIn from "layouts/authentication/sign-in";
// import SignUp from "layouts/authentication/sign-up";

// @mui icons
import Icon from "@mui/material/Icon";

import Parse from "parse";

Parse.serverURL = process.env.serverURL || "http://192.168.1.25:1337/parse";
Parse.initialize("ims", "ims123");
// Parse.liveQueryServerURL = "ws://192.168.1.25:8080/parse";

// console.log(Parse.User.current());
// let generateRoutes = async ()=>{}
let routes;
routes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: <Dashboard />,
  },
  {
    type: "collapse",
    name: "Inventory",
    key: "inventory",
    icon: <Icon fontSize="small">inventory_outlined</Icon>,
    route: "/inventory",
    component: <Inventory />,
  },
];
if (Parse.User.current() && Parse.User.current().get("MIR")) {
  routes.push({
    type: "collapse",
    name: "Material Issue Request",
    key: "material-issue-request",
    icon: <Icon fontSize="small">add_circle_outline_outlined</Icon>,
    route: "/material-issue-request",
    component: <MaterialIssueRequest />,
  });
}
if (Parse.User.current() && Parse.User.current().get("CNM")) {
  routes.push({
    type: "collapse",
    name: "Create New Material",
    key: "Create-New-Material",
    icon: <Icon fontSize="small">note_add_outlined</Icon>,
    route: "/Create-New-Material",
    component: <CreateNewMaterial />,
  });
}
if (Parse.User.current() && Parse.User.current().get("CNU")) {
  routes.push({
    type: "collapse",
    name: "Create New User",
    key: "Create-New-User",
    icon: <Icon fontSize="small">person_add_alt_outlined</Icon>,
    route: "/Create-New-User",
    component: <CreateNewUser />,
  });
}
if (Parse.User.current() && Parse.User.current().get("CGR")) {
  routes.push({
    type: "collapse",
    name: "Create GR",
    key: "create-GR",
    icon: <Icon fontSize="small">add_task_outlined</Icon>,
    route: "/create-GR",
    component: <CreateGR />,
  });
}
routes.push({
  type: "collapse",
  name: "Profile",
  key: "profile",
  icon: <Icon fontSize="small">person</Icon>,
  route: "/profile",
  component: <Profile />,
});
routes.push({
  type: "collapse",
  name: "Info",
  key: "info",
  icon: <Icon fontSize="small">info_outlined</Icon>,
  route: "/info",
  component: <Info />,
});
// routes.push({
//   type: "collapse",
//   name: "Temp",
//   key: "temp",
//   icon: <Icon fontSize="small">dashboard</Icon>,
//   route: "/temp",
//   component: <Temp />,
// });
// }

// const routes = [
//   // {
//   //   type: "collapse",
//   //   name: "Temp",
//   //   key: "temp",
//   //   icon: <Icon fontSize="small">dashboard</Icon>,
//   //   route: "/temp",
//   //   component: <Temp />,
//   // },
//   {
//     type: "collapse",
//     name: "Dashboard",
//     key: "dashboard",
//     icon: <Icon fontSize="small">dashboard</Icon>,
//     route: "/dashboard",
//     component: <Dashboard />,
//   },
//   {
//     type: "collapse",
//     name: "Inventory",
//     key: "inventory",
//     icon: <Icon fontSize="small">inventory_outlined</Icon>,
//     route: "/inventory",
//     component: <Inventory />,
//   },
//   {
//     type: "collapse",
//     name: "Material Issue Request",
//     key: "material-issue-request",
//     icon: <Icon fontSize="small">add_circle_outline_outlined</Icon>,
//     route: "/material-issue-request",
//     component: <MaterialIssueRequest />,
//   },
//   // {
//   //   type: "collapse",
//   //   name: "Edit Material Issue Request",
//   //   key: "Edit-Material-Issue-Request",
//   //   icon: <Icon fontSize="small">dashboard</Icon>,
//   //   route: "/Edit-Material-Issue-Request",
//   //   component: <MaterialIssueRequest />,
//   // },
//   {
//     type: "collapse",
//     name: "Create New Material",
//     key: "Create-New-Material",
//     icon: <Icon fontSize="small">note_add_outlined</Icon>,
//     route: "/Create-New-Material",
//     component: <CreateNewMaterial />,
//   },
//   {
//     type: "collapse",
//     name: "Create New User",
//     key: "Create-New-User",
//     icon: <Icon fontSize="small">person_add_alt_outlined</Icon>,
//     route: "/Create-New-User",
//     component: <CreateNewUser />,
//   },
//   {
//     type: "collapse",
//     name: "Create GR",
//     key: "create-GR",
//     icon: <Icon fontSize="small">add_task_outlined</Icon>,
//     route: "/create-GR",
//     component: <CreateGR />,
//   },
//   // {
//   //   type: "collapse",
//   //   name: "Tables",
//   //   key: "tables",
//   //   icon: <Icon fontSize="small">table_view</Icon>,
//   //   route: "/tables",
//   //   component: <Tables />,
//   // },
//   // {
//   //   type: "collapse",
//   //   name: "Billing",
//   //   key: "billing",
//   //   icon: <Icon fontSize="small">receipt_long</Icon>,
//   //   route: "/billing",
//   //   component: <Billing />,
//   // },
//   // {
//   //   type: "collapse",
//   //   name: "RTL",
//   //   key: "rtl",
//   //   icon: <Icon fontSize="small">format_textdirection_r_to_l</Icon>,
//   //   route: "/rtl",
//   //   component: <RTL />,
//   // },
//   // {
//   //   type: "collapse",
//   //   name: "Notifications",
//   //   key: "notifications",
//   //   icon: <Icon fontSize="small">notifications</Icon>,
//   //   route: "/notifications",
//   //   component: <Notifications />,
//   // },
//   {
//     type: "collapse",
//     name: "Profile",
//     key: "profile",
//     icon: <Icon fontSize="small">person</Icon>,
//     route: "/profile",
//     component: <Profile />,
//   },
//   // {
//   //   type: "collapse",
//   //   name: "Sign In",
//   //   key: "sign-in",
//   //   icon: <Icon fontSize="small">login</Icon>,
//   //   route: "/authentication/sign-in",
//   //   component: <SignIn />,
//   // },
//   // {
//   //   type: "collapse",
//   //   name: "Sign Up",
//   //   key: "sign-up",
//   //   icon: <Icon fontSize="small">assignment</Icon>,
//   //   route: "/authentication/sign-up",
//   //   component: <SignUp />,
//   // },
// ];

export default routes;
