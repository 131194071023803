/**
=========================================================
* Material Dashboard 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
/* eslint-disable react/prop-types */
/* eslint-disable no-use-before-define */
/* eslint-disable consistent-return */
/* eslint-disable prefer-template */
/* eslint-disable */
import React, { useState, useEffect, useMemo, useRef } from "react";
// @mui material components
import Modal from "@mui/material/Modal";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDBadge from "components/MDBadge";
import MDButton from "components/MDButton";

import MDInput from "components/MDInput";
import MDSnackbar from "components/MDSnackbar";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

import Parse from "parse";
import { initializeParse, useParseQuery } from "@parse/react";

import { useMaterialUIController } from "context";
import Avatar from "@mui/material/Avatar";
import { useNavigate } from "react-router-dom";

const asyncForEach = async (array, callback) => {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
};

function Dashboard() {
  let navigate = useNavigate();
  useEffect(async () => {
    if (!Parse.User.current()) {
      navigate("/authentication/sign-in");
    }
    // subordinates = await getSubordinates();
    return () => {
      // console.log("subscription closed");
    };
  }, []);
  useEffect(async () => {
    if (!Parse.User.current()) {
      navigate("/authentication/sign-in");
    }
  }, [Parse.User.current()]);

  function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

  const [open, setOpen] = useState(false);
  const [currentTransaction, setCurrentTransaction] = useState("");
  const handleOpen = (transaction) => {
    setDetails(transaction);
    setCurrentTransaction(transaction);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setTransactionDetails([]);
  };
  const [ongoingRequests, setOngoingRequests] = useState([]);
  const prevOngoingRequests = usePrevious(ongoingRequests);
  const [completedRequests, setCompletedRequests] = useState([]);
  const prevCompletedRequests = usePrevious(completedRequests);
  const [transactionDetails, setTransactionDetails] = useState([]);
  const [subordinates, setSubordinates] = useState([]);

  const [success, setSuccess] = useState("");
  const [info, setInfo] = useState("");
  const [warning, setWarning] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const [notificationResults, setNotificationResults] = useState([]);
  const [awaitingApprovalNotifications, setAwaitingApprovalNotifications] = useState([]);
  const [approvedNotifications, setApprovedNotifications] = useState([]);
  const [rejectedNotifications, setRejectedNotifications] = useState([]);
  const [deliveredNotifications, setDeliveredNotifications] = useState([]);
  const [confirmedNotifications, setConfirmedNotifications] = useState([]);
  const [talliedNotifications, setTalliedNotifications] = useState([]);
  // const [completedNotifications, setErrorNotifications] = useState([]);

  const [successSB, setSuccessSB] = useState(false);
  const [infoSB, setInfoSB] = useState(false);
  const [warningSB, setWarningSB] = useState(false);
  const [errorSB, setErrorSB] = useState(false);
  // const [error, setError] = useState("");

  const openSuccessSB = () => setSuccessSB(true);
  const closeSuccessSB = () => setSuccessSB(false);
  const openInfoSB = () => setInfoSB(true);
  const closeInfoSB = () => setInfoSB(false);
  const openWarningSB = () => setWarningSB(true);
  const closeWarningSB = () => setWarningSB(false);
  const openErrorSB = () => setErrorSB(true);
  const closeErrorSB = () => setErrorSB(false);

  const renderSuccessSB = (
    <MDSnackbar
      color="success"
      icon="check"
      title="IMS Demo"
      content={success}
      dateTime=""
      open={successSB}
      onClose={closeSuccessSB}
      close={closeSuccessSB}
      bgWhite
    />
  );

  const renderInfoSB = (
    <MDSnackbar
      icon="notifications"
      title="Material Dashboard"
      content={info}
      dateTime=""
      open={infoSB}
      onClose={closeInfoSB}
      close={closeInfoSB}
    />
  );

  const renderWarningSB = (
    <MDSnackbar
      color="warning"
      icon="warning_amber_outlined"
      title="IMS Demo"
      content={warning}
      dateTime=""
      open={warningSB}
      onClose={closeWarningSB}
      close={closeWarningSB}
      bgWhite
    />
  );

  const renderErrorSB = (
    <MDSnackbar
      color="error"
      icon="warning"
      title="IMS Demo"
      content={errorMessage || "Something went wrong. Try again or contact support."}
      dateTime=""
      open={errorSB}
      onClose={closeErrorSB}
      close={closeErrorSB}
      bgWhite
    />
  );

  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  // get all subordinates for current users to get requests created by them so they can be approved or rejected.
  const getSubordinates = async () => {
    let userQuery = new Parse.Query("_User");
    let tempSubordinates = [];
    // manager = userQuery.equalTo("user", Parse.User.current().attributes.manager);
    // subordinates = userQuery.equalTo("user", Parse.User.current().attributes.manager);
    let allUsers = await userQuery.findAll();
    let currentUser = await Parse.User.current();
    allUsers.forEach((user, index) => {
      // if (user.attributes.manager) {
      // console.log(`${user.get("name")}: ${JSON.stringify(user.get("manager"))}`);
      // }
      if (user.attributes.manager && currentUser.get("name") === user.attributes.manager.name) {
        tempSubordinates.push(user.id);
        // console.log(user);
      }
      // console.log("managers");
      // console.log(user.attributes.manager.id);
      // if (index === allUsers.length - 1) {
      //   // console.log(subordinates);
      //   return tempSubordinates;
      // }
    });
    return tempSubordinates;
  };

  useMemo(async () => {
    if (Parse.User.current()) {
      let sub = await getSubordinates();
      setSubordinates(sub);
    }
    // let infoNotifications
    setAwaitingApprovalNotifications(
      (await localStorage.getItem("awaitingApprovalNotifications")) || []
    );
    setApprovedNotifications((await localStorage.getItem("approvedNotifications")) || []);
    setRejectedNotifications((await localStorage.getItem("rejectedNotifications")) || []);
    setDeliveredNotifications((await localStorage.getItem("deliveredNotifications")) || []);
    setConfirmedNotifications((await localStorage.getItem("confirmedNotifications")) || []);
    setTalliedNotifications((await localStorage.getItem("talliedNotifications")) || []);
  }, []);

  // for manager. needs more fine tuning
  const approveTransaction = async (id) => {
    let transactionsQuery = new Parse.Query("Transactions");
    transactionsQuery.equalTo("objectId", id);
    let approvedTransactions = await transactionsQuery.find();
    // console.log(approvedTransactions);
    approvedTransactions[0].set("status", "approved");
    await approvedTransactions[0].save();
    console.log("approved: " + id);
  };

  // for store manager to click on delivery of items
  const deliverTransaction = async (id) => {
    let transactionsQuery = new Parse.Query("Transactions");
    transactionsQuery.equalTo("objectId", id);
    let approvedTransactions = await transactionsQuery.find();
    // console.log(approvedTransactions);
    approvedTransactions[0].set("status", "delivered");
    await approvedTransactions[0].save();
    console.log("approved: " + id);
  };

  const rejectTransaction = async (id) => {
    let transactionsQuery = new Parse.Query("Transactions");
    transactionsQuery.equalTo("objectId", id);
    let approvedTransactions = await transactionsQuery.find();
    // console.log(approvedTransactions);
    approvedTransactions[0].set("status", "rejected");
    await approvedTransactions[0].save();
    console.log("rejected: " + id);
  };

  // for user who created request to confirm receipt of goods
  const confirmTransaction = async (id) => {
    let transactionsQuery = new Parse.Query("Transactions");
    transactionsQuery.equalTo("objectId", id);
    let confirmedTransactions = await transactionsQuery.find();
    // console.log(confirmTransaction);
    confirmedTransactions[0].set("status", "confirmed");
    await confirmedTransactions[0].save();
    console.log("confirmed: " + id);
  };

  const deleteTransaction = async (id) => {
    let transactionsQuery = new Parse.Query("Transactions");
    transactionsQuery.equalTo("objectId", id);
    let confirmedTransactions = await transactionsQuery.find();
    confirmedTransactions[0].set("status", "deleted");
    await confirmedTransactions[0].save();
    console.log("deleted: " + id);
  };

  const Author = ({ image, name, email }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <Avatar name={name} size="sm">
        {name && name[0].toUpperCase()}
      </Avatar>

      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {name}
        </MDTypography>
        <MDTypography variant="caption">{email}</MDTypography>
      </MDBox>
    </MDBox>
  );

  const Job = ({ title, description }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
        {title}
      </MDTypography>
      <MDTypography variant="caption">{description}</MDTypography>
    </MDBox>
  );

  const setDetails = (transaction) => {
    // console.log(transaction);

    let color;
    switch (transaction.get("action")) {
      case "add":
        color = "success";
        break;
      case "subtract":
        color = "error";
        break;

      default:
        break;
    }
    let rows = [];
    if (transaction.attributes.details) {
      asyncForEach(transaction.attributes.details, async (transactionItem, index) => {
        // console.log("transactionItem");
        // console.log(transactionItem);
        let Inventory = new Parse.Query("Inventory");
        Inventory.equalTo("objectId", transactionItem.id);
        let materials = await Inventory.find();

        rows.push({
          itemID: transactionItem.id,
          name: materials[0].attributes.name,
          // quantity: (transactionItem.quantity),
          quantity: (
            <MDTypography display="block" variant="caption" color={color} fontWeight="medium">
              {transactionItem.quantity}
            </MDTypography>
          ),
          unit: materials[0].attributes.unit,
        });
        if (index === transaction.attributes.details.length - 1) {
          setTransactionDetails(rows);
        }
      });
    }
    // return rows;
    // console.log(rows);
  };
  const ongoingQuery = new Parse.Query("Transactions");
  ongoingQuery.containedIn("status", [
    "awaiting approval",
    "approved",
    // "rejected",
    "delivered",
    // "confirmed",
    // "tallied",
  ]);
  // Set results ordering
  ongoingQuery.descending("updatedAt");

  // // Declare hook and variables to hold hook responses
  const {
    isLive: ongoingIsLive,
    isLoading: ongoingIsLoading,
    isSyncing: ongoingIsSyncing,
    results: ongoingResults,
    count: ongoingCount,
    error: ongoingError,
    reload: ongoingReload,
  } = useParseQuery(ongoingQuery, {
    enableLocalDatastore: false, // Enables cache in local datastore (default: true)
    enableLiveQuery: true, // Enables live query for real-time update (default: true)
  });

  // useEffect(() => {
  //   console.log(ongoingResults);
  //   // console.log(error);
  // }, [ongoingResults]);

  // console.log(count);

  const completedQuery = new Parse.Query("Transactions");
  completedQuery.containedIn("status", [
    // "awaiting approval",
    // "approved",
    "rejected",
    // "delivered",
    "confirmed",
    "tallied",
  ]);
  // Set results ordering
  completedQuery.descending("updatedAt");
  completedQuery.limit(100);

  // // Declare hook and variables to hold hook responses
  const {
    isLive: completedIsLive,
    isLoading: completedIsLoading,
    isSyncing: completedIsSyncing,
    results: completedResults,
    count: completedCount,
    error: completedError,
    reload: completedReload,
  } = useParseQuery(completedQuery, {
    enableLocalDatastore: false, // Enables cache in local datastore (default: true)
    enableLiveQuery: true, // Enables live query for real-time update (default: true)
  });

  useEffect(() => {
    console.log(completedResults);
    // console.log(error);
  }, [completedResults]);

  // const prevResults = usePrevious(results);
  const prevNotificationResults = usePrevious(notificationResults);

  const createRows = (transactions) => {
    let rows = [];
    if (transactions.length) {
      transactions.forEach(async (transaction) => {
        if (transaction) {
          let color = () => {
            let returnColor;
            switch (transaction.attributes.status) {
              case "awaiting approval":
                returnColor = "warning";
                break;
              case "approved":
                returnColor = "info";
                break;
              case "delivered":
                returnColor = "primary";
                break;
              case "confirmed":
                returnColor = "success";
                break;
              case "tallied":
                returnColor = "success";
                break;
              case "rejected":
                returnColor = "error";
                break;

              default:
                break;
            }
            return returnColor;
          };
          rows.push({
            RID: transaction.id,
            name: (
              <Author
                image={null}
                name={transaction.get("user").attributes.name || ""}
                email={transaction.get("user").attributes.email || ""}
              />
            ),
            function: (
              <Job
                title={transaction.get("user").attributes.role || ""}
                description="Organization"
              />
            ),
            status: (
              <MDBox ml={-1}>
                <MDBadge
                  badgeContent={transaction.attributes.status}
                  color={color()}
                  variant="gradient"
                  size="sm"
                />
              </MDBox>
            ),
            date: (
              <MDTypography variant="caption" color="text" fontWeight="medium">
                {transaction.createdAt.toDateString("en-IN")}
              </MDTypography>
            ),
            actions: (
              <>
                <MDTypography
                  component="a"
                  href="#"
                  variant="caption"
                  color="text"
                  fontWeight="medium"
                  onClick={(e) => {
                    e.preventDefault();
                    handleOpen(transaction);
                  }}
                >
                  View
                </MDTypography>

                {((transaction.attributes.status === "awaiting approval" &&
                  transaction.get("user").id === Parse.User.current().id) ||
                  (transaction.attributes.status === "approved" &&
                    transaction.get("user").get("MRA"))) && (
                  <MDTypography
                    component="a"
                    href={`material-issue-request?edit=${transaction.id}`}
                    variant="caption"
                    color="text"
                    fontWeight="medium"
                  >
                    {" | "}Edit
                  </MDTypography>
                )}
                {transaction.attributes.status === "awaiting approval" &&
                  Parse.User.current().attributes.MRA && (
                    <MDTypography
                      component="a"
                      href={`?approve=${transaction.id}`}
                      variant="caption"
                      color="text"
                      fontWeight="medium"
                      onClick={(e) => {
                        e.preventDefault();
                        approveTransaction(transaction.id);
                      }}
                    >
                      {" | "}Approve
                    </MDTypography>
                  )}
                {/* can be reject by manager if status is awaiting approval & can be rejected by store manager if status is approved */}
                {((transaction.attributes.status === "awaiting approval" &&
                  Parse.User.current().attributes.MRA) ||
                  (transaction.attributes.status === "approved" &&
                    Parse.User.current().attributes.CNM)) && (
                  <MDTypography
                    component="a"
                    href={`?reject=${transaction.id}`}
                    variant="caption"
                    color="text"
                    fontWeight="medium"
                    onClick={(e) => {
                      e.preventDefault();
                      rejectTransaction(transaction.id);
                    }}
                  >
                    {" | "}Reject
                  </MDTypography>
                )}
                {transaction.attributes.status === "approved" &&
                  Parse.User.current().attributes.CNM && (
                    <MDTypography
                      component="a"
                      href={`?approve=${transaction.id}`}
                      variant="caption"
                      color="text"
                      fontWeight="medium"
                      onClick={(e) => {
                        e.preventDefault();
                        deliverTransaction(transaction.id);
                      }}
                    >
                      {" | "}Deliver
                    </MDTypography>
                  )}
                {transaction.get("user").id === Parse.User.current().id &&
                  transaction.attributes.status === "delivered" && (
                    <MDTypography
                      component="a"
                      href={`?confirm=${transaction.id}`}
                      variant="caption"
                      color="text"
                      fontWeight="medium"
                      onClick={(e) => {
                        e.preventDefault();
                        confirmTransaction(transaction.id);
                      }}
                    >
                      {" | "} Confirm
                    </MDTypography>
                  )}
                {((transaction.attributes.status === "awaiting approval" &&
                  transaction.get("user").id === Parse.User.current().id) ||
                  (transaction.attributes.status === "approved" &&
                    transaction.get("user").get("MRA"))) && (
                  <MDTypography
                    component="a"
                    href={`?delete=${transaction.id}`}
                    variant="caption"
                    color="text"
                    fontWeight="medium"
                    onClick={(e) => {
                      e.preventDefault();
                      deleteTransaction(transaction.id);
                    }}
                  >
                    {" | "}Delete
                  </MDTypography>
                )}
              </>
            ),
          });
        }
      });
    }
    return rows;
  };
  // let subordinates;

  useEffect(async () => {
    // let subordinates = await getSubordinates();
    if (ongoingResults !== undefined) {
      let ongoingTransactoins = [];
      ongoingResults.forEach((result) => {
        if (
          result.get("status") === "awaiting approval" ||
          result.get("status") === "approved" ||
          result.get("status") === "delivered"
        ) {
          ongoingTransactoins.push(result);
        }
      });

      let currentUserResults = [];
      ongoingTransactoins.forEach((result) => {
        if (result) {
          // console.log(subordinates);
          if (result.get("user").id === Parse.User.current().id) {
            currentUserResults.push(result);
          }
        }
      });

      let subordinateResults = [];
      ongoingTransactoins.forEach((result) => {
        if (result) {
          // console.log(result.get("user").id);
          if (subordinates && subordinates.includes(result.get("user").id)) {
            subordinateResults.push(result);
          }
        }
      });

      let userResults = currentUserResults.concat(subordinateResults);
      userResults = userResults.sort((a, b) => a.get("updatedAt") > b.get("updatedAt"));

      let storeManagerResults = [];
      ongoingTransactoins.forEach((result) => {
        if (result) {
          if (
            result.get("status") !== "awaiting approval" &&
            Parse.User.current().get("CGR") === true
          ) {
            storeManagerResults.push(result);
          }
        }
      });

      storeManagerResults = storeManagerResults.sort(
        (a, b) => a.get("updatedAt") > b.get("updatedAt")
      );

      let rows;

      if (Parse.User.current().get("CGR")) {
        rows = createRows(storeManagerResults);
        setNotificationResults(storeManagerResults);
      } else {
        rows = createRows(userResults);
        // console.log(userResults);
        setNotificationResults(userResults);
      }
      setOngoingRequests(rows);
    }
    if (completedResults !== undefined) {
      let completedTransactoins = [];
      completedResults.forEach((result) => {
        if (
          result.get("status") === "confirmed" ||
          result.get("status") === "tallied" ||
          result.get("status") === "rejected"
        ) {
          completedTransactoins.push(result);
        }
      });

      let currentUserResults = [];
      completedTransactoins.map((result) => {
        if (result) {
          // console.log(subordinates);
          if (result.get("user").id === Parse.User.current().id) {
            currentUserResults.push(result);
          }
        }
      });

      let subordinateResults = [];
      completedTransactoins.map((result) => {
        if (result) {
          if (subordinates && subordinates.includes(result.get("user").id)) {
            subordinateResults.push(result);
          }
        }
      });

      let userResults = currentUserResults.concat(subordinateResults);
      userResults = userResults.sort((a, b) => a.get("updatedAt") > b.get("updatedAt"));

      let storeManagerResults = [];
      completedTransactoins.forEach((result) => {
        if (result) {
          if (Parse.User.current().get("CGR") === true) {
            storeManagerResults.push(result);
          }
        }
      });
      storeManagerResults = storeManagerResults.sort(
        (a, b) => a.get("updatedAt") > b.get("updatedAt")
      );

      let rows;

      if (Parse.User.current().get("CGR")) {
        rows = createRows(storeManagerResults);
      } else {
        rows = createRows(userResults);
      }
      setCompletedRequests(rows);
    }
  }, [ongoingResults, completedResults, subordinates]);

  useEffect(() => {
    if (
      prevNotificationResults &&
      prevNotificationResults.length &&
      prevNotificationResults.length > notificationResults.length
    ) {
      // console.log("entered tallied");
      let difference = null;
      difference = prevNotificationResults.filter((x) => !notificationResults.includes(x));
      if (
        difference &&
        difference.length &&
        difference[0].attributes.status === "rejected" &&
        !rejectedNotifications.includes(difference[0].id)
      ) {
        // await closeInfoSB()
        setErrorMessage(`${difference[0].id} has been rejected!`);
        openErrorSB();
        let newInfoNotifications = [...rejectedNotifications, difference[0].id];
        setRejectedNotifications(newInfoNotifications);
        localStorage.setItem("rejectedNotifications", newInfoNotifications);
      }
      if (
        difference &&
        difference.length &&
        difference[0].attributes.status === "confirmed" &&
        !talliedNotifications.includes(difference[0].id)
      ) {
        // await closeInfoSB()
        setSuccess(`${difference[0].id} has been completed!`);
        openSuccessSB();
        let newInfoNotifications = [...talliedNotifications, difference[0].id];
        setTalliedNotifications(newInfoNotifications);
        localStorage.setItem("talliedNotifications", newInfoNotifications);
      }
    }
    if (
      prevNotificationResults &&
      prevNotificationResults.length &&
      !(prevNotificationResults.length > notificationResults.length)
    ) {
      let difference = null;
      difference = notificationResults.filter((x) => !prevNotificationResults.includes(x));
      // console.log(difference);
      if (
        difference &&
        difference.length &&
        difference[0].attributes.status === "awaiting approval" &&
        !awaitingApprovalNotifications.includes(difference[0].id)
      ) {
        setWarning(`New request awaiting approval`);
        openWarningSB();
        let newInfoNotifications = [...awaitingApprovalNotifications, difference[0].id];
        setAwaitingApprovalNotifications(newInfoNotifications);
        localStorage.setItem("awaitingApprovalNotifications", newInfoNotifications);
      }
      if (
        difference &&
        difference.length &&
        difference[0].attributes.status === "approved" &&
        !approvedNotifications.includes(difference[0].id)
      ) {
        // await closeInfoSB()
        setInfo(`${difference[0].id} has been approved!`);
        openInfoSB();
        let newInfoNotifications = [...approvedNotifications, difference[0].id];
        setApprovedNotifications(newInfoNotifications);
        localStorage.setItem("approvedNotifications", newInfoNotifications);
      }
      // transaction status changed to delivered
      if (
        difference &&
        difference.length &&
        difference[0].attributes.status === "delivered" &&
        !deliveredNotifications.includes(difference[0].id)
      ) {
        // await closeInfoSB()
        setInfo(`${difference[0].id} has been delivered!`);
        openInfoSB();
        let newInfoNotifications = [...deliveredNotifications, difference[0].id];
        setDeliveredNotifications(newInfoNotifications);
        localStorage.setItem("deliveredNotifications", newInfoNotifications);
      }
      // transaction status changed to confirmed
      if (
        difference &&
        difference.length &&
        difference[0].attributes.status === "confirmed" &&
        !confirmedNotifications.includes(difference[0].id)
      ) {
        // await closeInfoSB()
        setInfo(`${difference[0].id} has been confirmed!`);
        openInfoSB();
        let newInfoNotifications = [...confirmedNotifications, difference[0].id];
        setConfirmedNotifications(newInfoNotifications);
        localStorage.setItem("confirmedNotifications", newInfoNotifications);
      }
    }
  }, [notificationResults]);

  // useEffect(() => {
  //   console.log(ongoingResults);
  //   console.log(completedResults);
  //   console.log(ongoingIsSyncing);
  //   // console.log(ongoingCount);
  //   console.log(completedCount);
  // }, [ongoingResults, completedResults, ongoingIsSyncing, completedCount]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Ongoing Requests
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{
                    columns: [
                      { Header: "RID", accessor: "RID", width: "1%", align: "left" },
                      { Header: "requestor", accessor: "name", width: "5%", align: "left" },
                      { Header: "function", accessor: "function", align: "left" },
                      { Header: "status", accessor: "status", align: "center" },
                      { Header: "date", accessor: "date", align: "center" },
                      { Header: "actions", accessor: "actions", align: "center" },
                    ],
                    rows: ongoingRequests,
                  }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
                {ongoingIsLoading && (
                  <MDTypography mb={2} px={5} variant="h6" fontWeight={"light"}>
                    Loading...
                  </MDTypography>
                )}
                {!ongoingIsLoading &&
                  prevOngoingRequests.length === 0 &&
                  ongoingRequests.length === 0 && (
                    <MDTypography mb={2} px={5} variant="h6" fontWeight={"light"}>
                      There are no requests that require your attention at the moment.
                    </MDTypography>
                  )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Completed Requests
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{
                    columns: [
                      { Header: "RID", accessor: "RID", width: "1%", align: "left" },
                      { Header: "requestor", accessor: "name", width: "5%", align: "left" },
                      { Header: "function", accessor: "function", align: "left" },
                      { Header: "status", accessor: "status", align: "center" },
                      { Header: "date", accessor: "date", align: "center" },
                      { Header: "actions", accessor: "actions", align: "center" },
                    ],
                    rows: completedRequests,
                  }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
                {completedIsLoading && (
                  <MDTypography mb={2} px={5} variant="h6" fontWeight={"light"}>
                    Loading...
                  </MDTypography>
                )}
                {!completedIsLoading &&
                  prevCompletedRequests.length === 0 &&
                  completedRequests.length === 0 && (
                    <MDTypography mb={2} px={5} variant="h6" fontWeight={"light"}>
                      There are no completed requests at the moment.
                    </MDTypography>
                  )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <MDBox
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "auto",
            maxWidth: "xs",
          }}
          color={darkMode ? "dark" : "light"}
          bgColor={darkMode ? "dark" : "light"}
          variant="gradient"
          borderRadius="lg"
          shadow="lg"
          opacity={1}
          p={2}
        >
          <MDTypography id="modal-modal-title" variant="h6" component="h2">
            Request Details
          </MDTypography>
          <MDBox id="modal-modal-description" pt={3}>
            <DataTable
              table={{
                columns: [
                  { Header: "itemID", accessor: "itemID", width: "1%", align: "left" },
                  { Header: "name", accessor: "name", width: "60%", align: "left" },
                  { Header: "quantity", accessor: "quantity", align: "right" },
                  { Header: "unit", accessor: "unit", align: "left" },
                ],
                rows: transactionDetails,
              }}
              isSorted
              entriesPerPage={{ defaultValue: 5, entries: [5, 10, 15, 20, 25] }}
              showTotalEntries
              pagination={{ color: "primary" }}

              // noEndBorder
            />
            <Grid
              container
              // xs={12}
              direction="row"
              justifyContent="space-evenly"
              alignItems="center"
              px="auto"
              mb={1}
              mt={3}
            >
              {currentTransaction &&
                currentTransaction.attributes.status !== "confirmed" &&
                currentTransaction.attributes.status !== "tallied" &&
                currentTransaction.attributes.status !== "rejected" &&
                currentTransaction.attributes.status !== "delivered" &&
                currentTransaction.get("user").id === Parse.User.current().id &&
                currentTransaction.attributes.status !== "approved" && (
                  <Grid item>
                    <MDButton
                      color="warning"
                      onClick={(e) => {
                        e.preventDefault();
                        navigate(`/material-issue-request?edit=${currentTransaction.id}`);
                      }}
                    >
                      Edit
                    </MDButton>
                  </Grid>
                )}
              {currentTransaction &&
                currentTransaction.attributes.status === "awaiting approval" &&
                Parse.User.current().attributes.MRA && (
                  <Grid item>
                    <MDButton
                      color="success"
                      onClick={(e) => {
                        e.preventDefault();
                        approveTransaction(currentTransaction.id);
                      }}
                    >
                      Approve
                    </MDButton>
                  </Grid>
                )}
              {currentTransaction &&
                ((currentTransaction.attributes.status === "awaiting approval" &&
                  Parse.User.current().attributes.MRA) ||
                  (currentTransaction.attributes.status === "approved" &&
                    Parse.User.current().attributes.CNM)) && (
                  <Grid item>
                    <MDButton
                      color="error"
                      onClick={(e) => {
                        e.preventDefault();
                        rejectTransaction(currentTransaction.id);
                      }}
                    >
                      Reject
                    </MDButton>
                  </Grid>
                )}
              {currentTransaction &&
                currentTransaction.attributes.status === "approved" &&
                Parse.User.current().attributes.CNM && (
                  <Grid item>
                    <MDButton
                      color="success"
                      onClick={(e) => {
                        e.preventDefault();
                        deliverTransaction(currentTransaction.id);
                      }}
                    >
                      Deliver
                    </MDButton>
                  </Grid>
                )}

              {currentTransaction &&
                currentTransaction.attributes.status === "delivered" &&
                currentTransaction.get("user").id === Parse.User.current().id && (
                  <Grid item>
                    <MDButton
                      color="success"
                      onClick={(e) => {
                        e.preventDefault();
                        confirmTransaction(currentTransaction.id);
                      }}
                    >
                      Confirm
                    </MDButton>
                  </Grid>
                )}
              {currentTransaction &&
                currentTransaction.attributes.status !== "confirmed" &&
                currentTransaction.attributes.status !== "tallied" &&
                currentTransaction.attributes.status !== "rejected" &&
                currentTransaction.attributes.status !== "delivered" &&
                currentTransaction.get("user").id === Parse.User.current().id &&
                (Parse.User.current().attributes.MRA
                  ? true
                  : currentTransaction.attributes.status !== "approved") && (
                  <Grid item>
                    <MDButton
                      color="error"
                      onClick={(e) => {
                        e.preventDefault();
                        deleteTransaction(currentTransaction.id);
                      }}
                    >
                      Delete
                    </MDButton>
                  </Grid>
                )}
            </Grid>
          </MDBox>
        </MDBox>
      </Modal>
      {renderSuccessSB}
      {renderInfoSB}
      {renderWarningSB}
      {renderErrorSB}

      {/* <Footer /> */}
    </DashboardLayout>
  );
}

export default Dashboard;
