/**
=========================================================
* Material Dashboard 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "App";

import Parse from "parse";
import { initializeParse } from "@parse/react";

import { MaterialUIControllerProvider } from "context";

// initializeParse("http://192.168.1.25:1337/parse", "ims", "ims123");
// initializeParse("https://ims-api.arm.ravimakes.com/parse", "ims", "ims123");
// Soft UI Context Provider

Parse.serverURL = process.env.serverURL || "https://ims-api.arm.ravimakes.com/parse";
Parse.initialize("ims", "ims", "ims123");
Parse.liveQueryServerURL = "wss://ims-api.arm.ravimakes.com/parse";

// Parse.serverURL = process.env.serverURL || "http://192.168.1.25:1337/parse";
// Parse.initialize("ims", "ims123");
// Parse.liveQueryServerURL = "ws://192.168.1.25:1337/parse";
Parse.enableLocalDatastore();

// let currentUser = Parse.User.current();
// if (currentUser) {
//   // do stuff with the user
//   Parse.User.logOut();
// }
ReactDOM.render(
  <BrowserRouter>
    <MaterialUIControllerProvider>
      <App />
    </MaterialUIControllerProvider>
  </BrowserRouter>,
  document.getElementById("root")
);
