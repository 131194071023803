import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDBadge from "components/MDBadge";
import MDButton from "components/MDButton";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

import Parse from "parse";
import { useParseQuery } from "@parse/react";

function Inventory() {
  let navigate = useNavigate();
  useEffect(async () => {
    if (!Parse.User.current()) {
      navigate("/authentication/sign-in");
    }
    // subordinates = await getSubordinates();
    return () => {
      // console.log("subscription closed");
    };
  }, []);
  useEffect(async () => {
    if (!Parse.User.current()) {
      navigate("/authentication/sign-in");
    }
  }, [Parse.User.current()]);

  const [inventory, setInventory] = useState([]);
  const parseQuery = new Parse.Query("Inventory");
  // Get messages that involve both nicknames
  //   parseQuery.containedIn("status");
  // Set results ordering
  parseQuery.descending("updatedAt");

  // Include nickname fields, to enable name getting on list
  parseQuery.includeAll();

  // Declare hook and variables to hold hook responses
  const { isLive, isLoading, isSyncing, results, count, error, reload } = useParseQuery(
    parseQuery,
    {
      enableLocalDatastore: true, // Enables cache in local datastore (default: true)
      enableLiveQuery: true, // Enables live query for real-time update (default: true)
    }
  );
  const createRows = (items) => {
    let rows = [];
    if (items.length) {
      items.forEach(async (item) => {
        rows.push({
          id: items.id,
          name: item.get("name"),
          quantity: item.get("quantity"),
          unit: item.get("unit"),
          description: item.get("description"),
          updatedAt: item.get("updatedAt"),
        });
      });
    }
    return rows;
  };

  useEffect(() => {
    let allItems;
    if (results) {
      // console.log(results);
      allItems = results.map((item) => ({
        id: item.id,
        name: item.get("name"),
        quantity: item.get("quantity"),
        unit: item.get("unit"),
        description: item.get("description") || "",
        updatedAt: item.get("updatedAt").toDateString("en-IN"),
      }));
      // console.log(allItems);
      setInventory(allItems);
    }
  }, [results]);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Inventory
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{
                    columns: [
                      { Header: "Item ID", accessor: "id", width: "1%", align: "left" },
                      { Header: "item name", accessor: "name", width: "5%", align: "left" },
                      { Header: "description", accessor: "description", align: "left" },
                      { Header: "quantity", accessor: "quantity", align: "left" },
                      { Header: "unit", accessor: "unit", align: "center" },
                      { Header: "updated", accessor: "updatedAt", align: "center" },
                    ],
                    //   rows: inventory,
                    rows: inventory,
                  }}
                  isSorted
                  entriesPerPage={{ defaultValue: 10, entries: [5, 10, 15, 20, 25] }}
                  showTotalEntries
                  canSearch
                  //   noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>

      {/* <Footer /> */}
    </DashboardLayout>
  );
}

export default Inventory;
