/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
// @mui material components
// import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
// import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
// import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import Footer from "examples/Footer";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import MDInput from "components/MDInput";
import MDSnackbar from "components/MDSnackbar";

import Autocomplete from "@mui/material/Autocomplete";

import MDButton from "components/MDButton";
import Avatar from "@mui/material/Avatar";
import Switch from "@mui/material/Switch";
// import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";

// import DataTable from "examples/Tables/DataTable";

import Parse from "parse";

export default function Profile() {
  let navigate = useNavigate();
  useEffect(async () => {
    if (!Parse.User.current()) {
      navigate("/authentication/sign-in");
    }
    // subordinates = await getSubordinates();
    return () => {
      // console.log("subscription closed");
    };
  }, []);
  useEffect(async () => {
    if (!Parse.User.current()) {
      navigate("/authentication/sign-in");
    }
  }, [Parse.User.current()]);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("");
  const [manager, setManager] = useState(null);
  // const [subordinates, setSubordinates] = useState([]);
  // const [userImage, setUserImage] = useState("");
  const [MIR, setMIR] = useState(false);
  const [MRA, setMRA] = useState(false);
  const [CNM, setCNM] = useState(false);
  const [CGR, setCGR] = useState(false);
  const [CNU, setCNU] = useState(false);
  const [users, setUsers] = useState([]);

  const [inputWidth, setInputWidth] = useState(0);
  const [inputHeight, setInputHeight] = useState(0);
  const [successSB, setSuccessSB] = useState(false);
  const [infoSB, setInfoSB] = useState(false);
  const [warningSB, setWarningSB] = useState(false);
  const [errorSB, setErrorSB] = useState(false);
  const [error, setError] = useState("");

  const openSuccessSB = () => setSuccessSB(true);
  const closeSuccessSB = () => setSuccessSB(false);
  const openInfoSB = () => setInfoSB(true);
  const closeInfoSB = () => setInfoSB(false);
  const openWarningSB = () => setWarningSB(true);
  const closeWarningSB = () => setWarningSB(false);
  const openErrorSB = () => setErrorSB(true);
  const closeErrorSB = () => setErrorSB(false);

  const renderSuccessSB = (
    <MDSnackbar
      color="success"
      icon="check"
      title="IMS Demo"
      content="User created successfully"
      dateTime=""
      open={successSB}
      onClose={closeSuccessSB}
      close={closeSuccessSB}
      bgWhite
    />
  );

  const renderInfoSB = (
    <MDSnackbar
      icon="notifications"
      title="Material Dashboard"
      content="Hello, world! This is a notification message"
      dateTime=""
      open={infoSB}
      onClose={closeInfoSB}
      close={closeInfoSB}
    />
  );

  const renderWarningSB = (
    <MDSnackbar
      color="warning"
      icon="warning_amber_outlined"
      title="IMS Demo"
      content="Please fill all necessary fields"
      dateTime=""
      open={warningSB}
      onClose={closeWarningSB}
      close={closeWarningSB}
      bgWhite
    />
  );

  const renderErrorSB = (
    <MDSnackbar
      color="error"
      icon="warning"
      title="IMS Demo"
      content={error || "Something went wrong. Try again or contact support."}
      dateTime=""
      open={errorSB}
      onClose={closeErrorSB}
      close={closeErrorSB}
      bgWhite
    />
  );
  useEffect(() => {
    const currentUser = Parse.User.current();
    if (currentUser) {
      setName(currentUser.get("name"));
      setEmail(currentUser.get("email"));
      setManager(currentUser.get("manager"));
      setRole(currentUser.get("role"));
      setMIR(currentUser.get("MIR"));
      setMRA(currentUser.get("MRA"));
      setCNM(currentUser.get("CNM"));
      setCGR(currentUser.get("CGR"));
      setCNU(currentUser.get("CNU"));
    }
  }, []);

  const ref = useRef(null);

  useEffect(() => {
    // console.log("width", ref.current ? ref.current.offsetWidth : 0);
    setInputWidth(ref.current ? ref.current.offsetWidth : 0);
    // console.log("height", ref.current ? ref.current.offsetHeight : 0);
    setInputHeight(ref.current ? ref.current.offsetHeight : 0);
  }, [ref.current]);
  const tempFunc = async () => {
    // const session = await Parse.Session.current();
    // console.log(session);
    const uid = Parse.User.current().id;
    console.log(uid);
    console.log(await localStorage.getItem(`Parse_LDS__User_${uid}`));
    // console.log(auth);
  };
  // tempFunc();

  const signOut = async (e) => {
    e.preventDefault();
    const uid = Parse.User.current().id;
    const user = Parse.User.current();
    const sid = (await Parse.Session.current()).id;
    await Parse.User.logOut().then(async () => {
      console.log("Logged out");
      // await Parse.Cloud.run("handleError", { sid, uid });
      // await localStorage.removeItem("Parse/ims/currentUser");
      // await localStorage.removeItem(`Parse_LDS__User_${uid}`);
      navigate("/authentication/sign-in");
      // Parse.User._clearCache();
    });
    //   .catch(async (err) => {
    //     console.log(err.code);
    //     // Parse.User.logOut();
    //     await Parse.Cloud.run("handleError", { sid, uid });
    //     // await localStorage.removeItem("Parse/ims/currentUser");
    //     // await localStorage.removeItem(`Parse_LDS__User_${uid}`);
    //     navigate("/authentication/sign-in");
    //     Parse.User._clearCache();
    //   });
    // console.log(Parse.User.current());
    // await sessionStorage.clear();
    // await Parse.User.logOut();
    // navigate("/authentication/sign-in");
    // window.location = "/authentication/sign-in"
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Profile
                </MDTypography>
              </MDBox>
              <form>
                <Grid
                  container
                  // xs={12}
                  direction="row"
                  justifyContent="space-evenly"
                  alignItems="center"
                  px="auto"
                  mb={2}
                  mt={3}
                >
                  <Grid item>
                    <Grid
                      container
                      // xs={12}
                      direction="column"
                      justifyContent="space-evenly"
                      alignItems="center"
                      px="auto"
                      mb={2}
                      mt={3}
                    >
                      <Grid item mt={3}>
                        <Avatar sx={{ width: 125, height: 125 }} mb={2}>
                          {/* {name && name[0] + (name[name.indexOf(" ") + 1])} */}
                          {name ? name[0].toUpperCase() : null}
                        </Avatar>
                      </Grid>
                      <Grid item mt={3}>
                        <MDButton disabled color="info" mt={2}>
                          Upload Image
                        </MDButton>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid
                      container
                      // xs={12}
                      direction="column"
                      justifyContent="space-evenly"
                      alignItems="center"
                      px="auto"
                      mb={2}
                      mt={3}
                    >
                      <Grid item mt={3} xs={12}>
                        <MDInput
                          type="text"
                          label="Name"
                          ref={ref}
                          // mt={3}
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          disabled
                        />
                      </Grid>
                      <Grid item mt={3} xs={12}>
                        <MDInput
                          type="text"
                          label="Email"
                          // mt={3}
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          disabled
                        />
                      </Grid>
                      <Grid item mt={3} xs={12}>
                        <MDInput
                          type="text"
                          label="Function"
                          // mt={3}
                          value={role}
                          onChange={(e) => setRole(e.target.value)}
                          disabled
                        />
                      </Grid>
                      <Grid item mt={3} xs={12}>
                        {/* <MDInput
                          type="text"
                          label="Manager"
                          value={manager || ""}
                          disabled
                          style={{ height: "0" }}
                          // onChange={(e) => setManager(e.target.value)}
                        /> */}
                        <Autocomplete
                          defaultValue={null}
                          options={users}
                          getOptionLabel={(user) => (user ? user.name : "")}
                          value={manager}
                          onChange={(event, newValue) => setManager(newValue)}
                          sx={{ width: inputWidth }}
                          fullWidth={true}
                          renderInput={(params) => (
                            <MDInput
                              type="text"
                              label="Manager"
                              // fullWidth={true}
                              // variant="outlined"
                              // mt={3}
                              // value={material}
                              {...params}
                              inputProps={{
                                ...params.inputProps,
                                style: {
                                  // height: inputHeight,
                                  // height: "35px",
                                  padding: 3,
                                },
                              }}
                              // style={{ paddingY: 0 }}
                              // onChange={(e) => setManager(e.target.value)}
                              disabled
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid
                      container
                      // xs={12}
                      direction="column"
                      justifyContent="space-evenly"
                      alignItems="left"
                      px="auto"
                      mb={0}
                      mt={3}
                    >
                      <Grid item>
                        <FormControlLabel
                          control={
                            <Switch
                              label="Material Issue Request"
                              onChange={(e) => setMIR(e.target.checked)}
                              checked={MIR}
                              disabled
                            />
                          }
                          label="Material Issue Request"
                        />
                      </Grid>
                      <Grid item>
                        <FormControlLabel
                          control={
                            <Switch
                              label="Material Issue Request"
                              onChange={(e) => setMRA(e.target.checked)}
                              checked={MRA}
                              disabled
                            />
                          }
                          label="Material Request Approval"
                        />
                      </Grid>
                      <Grid item>
                        <FormControlLabel
                          control={
                            <Switch
                              label="Create New Material"
                              onChange={(e) => setCNM(e.target.checked)}
                              checked={CNM}
                              disabled
                            />
                          }
                          label="Create New Material"
                        />
                      </Grid>
                      <Grid item>
                        <FormControlLabel
                          control={
                            <Switch
                              label="Create GR"
                              onChange={(e) => setCGR(e.target.checked)}
                              checked={CGR}
                              disabled
                            />
                          }
                          label="Create GR"
                        />
                      </Grid>
                      <Grid item>
                        <FormControlLabel
                          control={
                            <Switch
                              label="Create New User"
                              onChange={(e) => {
                                setCNU(e.target.checked);
                                // console.log(e.target.checked);
                              }}
                              checked={CNU}
                              disabled
                            />
                          }
                          label="Create New User"
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  container
                  // xs={12}
                  direction="row"
                  justifyContent="space-evenly"
                  alignItems="center"
                  px="auto"
                  mb={2}
                  mt={3}
                >
                  <Grid item>
                    <MDButton color="info" onClick={signOut}>
                      Sign Out
                    </MDButton>
                  </Grid>
                  {/* <Grid item>
                    <MDButton color="success" onClick={addUser}>
                      Submit
                    </MDButton>
                  </Grid> */}
                </Grid>
              </form>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      {renderSuccessSB}
      {renderInfoSB}
      {renderWarningSB}
      {renderErrorSB}
      {/* <Footer /> */}
    </DashboardLayout>
  );
}
